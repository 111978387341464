import React from "react"
import Img from "../components/image-transform"

const Process = ({content}) => {
  return (
    <div className="section-process">
      <div className="container">
        <h2 className="title">{content.title}</h2>
        <div className="content">
          <div className="card-wrapper first-batch">
            {content.cardsFirstBatch.map ((item, i) => {
              return (
                <div className="card-item" key={i}>
                  <div className="text-holder">
                    <h5 className="card-title">{item.title}</h5>
                    <p className="card-description">{item.description}</p>
                  </div>
                  <div className="image-holder">
                    {
                      item.svg ?
                      <img src={item.image} className="image" alt={item.title}/>
                      :
                      <Img filename={item.image} className="image" alt={item.title}/>
                    }
                  </div>
                </div>
              )
            })}
          </div>
          <div className="card-wrapper second-batch">
            {content.cardsSecondBatch.map ((item, i) => {
              return (
                <div className="card-item" key={i}>
                  <div className="image-holder">
                    {
                      item.svg ? 
                      <img src={item.image} className="image" alt={item.title}/>
                      :
                      <Img filename={item.image} className="image" alt={item.title}/>
                    }
                  </div>
                  <div className="text-holder">
                    <h5 className="card-title">{item.title}</h5>
                    <p className="card-description">{item.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Process